import cn from 'classnames'
// Components
import styles from './Loader.module.scss'

export default function Loader ({ theme }) {
  const classes = { [styles.loader]: true }
  if (typeof theme === 'string') theme = [theme]
  theme?.forEach(t => {
    classes[styles[t]] = typeof t !== 'undefined'
  })

  return (
    <div>
      <div className={cn(classes)} />
    </div>
  )
}
