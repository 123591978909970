import React from 'react'
import ReactModal from 'react-modal'

import styles from './modal.module.scss'

const Modal = ({ isOpen, children, close }) => {
  if (typeof window !== 'undefined') {
    ReactModal.setAppElement('body')
  }

  return (
    <ReactModal
      isOpen={isOpen}
      overlayClassName={styles.modalOverlay}
      className={styles.modal}
    >
      <div className={styles.closeButton} onClick={() => close()}>
        <img src="/close-modal-grey.svg" alt="close icon" />
      </div>
      <div className={styles.content}>{children}</div>
    </ReactModal>
  )
}

export default Modal
