import { CSSProperties, ChangeEvent, FC } from 'react'
import cn from 'classnames'
import styles from './Input.module.scss'

interface InputProps {
  id?: string
  name?: string
  type?: string
  value: string | number
  placeholder: string
  min?: string
  max?: string
  // eslint-disable-next-line no-unused-vars
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
  disabled?: boolean
  showError?: boolean
  errorMessage?: string
  style?: CSSProperties
}

const Input: FC<InputProps> = ({
  id,
  name,
  type,
  value,
  placeholder,
  min,
  max,
  onChange,
  disabled,
  showError,
  errorMessage,
  style,
}) => {
  const errorMessageLong = errorMessage?.length > 35
  return (
    <div
      className={cn({
        [styles.container]: true,
        [styles.hasErrorMessage]: !!errorMessage,
        [styles.hasErrorMessageLong]: !!errorMessage && !!errorMessageLong,
        [styles.error]: showError,
      })}
    >
      <input
        id={id}
        name={name}
        type={type}
        className={styles.input}
        value={value}
        min={min}
        max={max}
        placeholder={placeholder}
        onChange={onChange}
        disabled={disabled}
        style={style}
      />

      {showError && !!errorMessage && (
        <div className={styles.error}>
          <div className={styles.icon} />
          <div className={styles.message}>{errorMessage}</div>
        </div>
      )}
    </div>
  )
}

export default Input
