import React from 'react'
import cn from 'classnames'
import OutsideClickHandler from 'react-outside-click-handler'
// Components
import Button from '../../newButton/Button'
import styles from './toastModal.module.scss'

type ToastModalProps = {
  theme?: 'success' | 'error'
  closeToast?: () => void
  title: string
  message: string
  email?: string
  buttonTitle?: string
  onClick?: () => void
}

const ToastModal = ({
  theme,
  closeToast,
  title,
  message,
  email,
  buttonTitle,
  onClick,
}: ToastModalProps) => (
  <div
    className={cn({
      [styles.toast]: true,
      [styles[theme]]: true,
    })}
  >
    <OutsideClickHandler onOutsideClick={closeToast}>
      <div className={styles.closeButton} onClick={closeToast} />
      <div className={styles.title}>{title}</div>
      <div className={styles.message}>{message}</div>
      <br />
      <div className={styles.message}>
        Need assistance? Please call <span>1-888-488-7203</span> or email{' '}
        {email || 'support@mawoopets.com'}
      </div>

      {!!buttonTitle && (
        <Button
          theme={['toast', 'medium']}
          title={buttonTitle}
          onClick={onClick}
        />
      )}
    </OutsideClickHandler>
  </div>
)

ToastModal.defaultProps = {
  theme: 'success',
}

export default ToastModal
