import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import { path } from 'ramda'
import ToastModal from '../breeder/toastModal/toastModal'
import Modal from '../customer/modal'
import Button from '../newButton/Button'
import Input from '../newInput/Input'
import { toast } from 'react-toastify'
import styles from './editPasswordModal.module.scss'

import { UPDATE_CUSTOMER } from '../../graphql'
import Loader from '../newLoader/Loader'

const EditPasswordModal = ({ isOpen, close }) => {
  const [save, { loading, data }] = useMutation(UPDATE_CUSTOMER, {
    onCompleted: (data) => {
      if (data) {
        toast(<ToastModal
          title='Success'
          message='Your password has been updated'
          buttonTitle='Ok'
          onClick={() => {
            close()
            toast.dismiss()
          }}
              />)
      } else {
        toast(<ToastModal theme='error' title='Error' message={data?.updateCustomer?.errorMessage} buttonTitle='Try Again' onClick={() => { toast.dismiss() }} />)
      }
    }
  })
  const [password, setPassword] = useState('')
  const [repeatPassword, setRepeatPassword] = useState('')
  const [error, setError] = useState('')
  const [view, setView] = useState(false)
  const [viewPass, setViewPass] = useState(false)
  const changeView = () => {
    setView(view => !view)
  }
  const changeViewPass = () => {
    setViewPass(view => !view)
  }

  const errorMessage = error || path(['updateCustomer', 'errorMessage'], data)

  if (loading) {
    return (
      <Modal isOpen>
        <div className={styles.modalHolder}>
          <div className={styles.center}>
            <Loader theme='tall' />
          </div>
        </div>
      </Modal>
    )
  }

  return (
    <Modal isOpen={isOpen} close={close}>
      <div className={styles.modalHolder}>
        <div className={styles.title}>Change Password</div>
        <p className={styles.subTitle}>Change your Friend a Puppy password.</p>
        <div className={styles.row}>
          <Input
            type={view ? 'text' : 'password'}
            value={password} placeholder='New password' onChange={(e) => setPassword(e.target.value)}
          />
          <div onClick={changeView} className={view ? styles.show : styles.notShow} />
        </div>
        <div className={styles.row}>
          <Input
            type={viewPass ? 'text' : 'password'}
            value={repeatPassword} placeholder='Confirm Password' onChange={(e) => setRepeatPassword(e.target.value)}

          />
          {
            errorMessage
              ? <div className={styles.errorMessage}><div className={styles.icon} />{errorMessage}</div>
              : null
          }
          <div onClick={changeViewPass} className={viewPass ? styles.show : styles.notShow} />
        </div>
        <div className={styles.row}>

          <Button
            theme={['large', 'pink-solid']}
            title='Save Changes'
            disabled={!password || !repeatPassword}
            onClick={() => {
              if (password !== repeatPassword) {
                setError('Passwords don\'t match')
                return
              } else {
                setError('')
              }
              save({ variables: { customer: { password } } })
            }}
          />

        </div>
      </div>
    </Modal>
  )
}

export default EditPasswordModal
